import React from 'react'

export default function Info() {
    return (
        <div className="info">
            <h3>Bilgilendirme:</h3>
            <br />
            <p>TaraBul, 4054 sayılı Rekabet kanunu ve 6502 sayılı Tüketicinin Korunması kanunu kapsamında, müşteri odaklı en iyi içeriği sunma ilkesine bağlı kalarak, tüketici haklarını korumak, tüketiciyi bilgilendirmek, daha bilinçli satın alma yapmasına destek olacak bütün ürün bilgilerini tüketiciye sunmak amacı ile kullanıma sunulmuş, fiyat karşılaştırması yapabilen “fiyat karşılaştırma motoru pazarı” uygulamasıdır.</p>
            <br />
            <p>TaraBul uygulamasında tüketici, fiyat, görsel özellik karşılaştırması yapabilmekte, talep etiği ürünleri farklı kriterlere göre arayabilmekte ve sıralamaya tabi tutabilmektedir. Tüketici uygun bulduğu ürünü satan firmaya kolayca ulaşabilir. Bu sayede uygulama fiyat rekabetinin de gerçekleştirmesine olanak sağlar.</p>
            <br />
            <p>Uygulama Firmalar/Satıcılar/Mağazalar için internet üzerinde ve pazarda daha fazla dönüşümlü potansiyel trafik oluşturmakta, sistemde daha geniş görünürlük sağlamaktadır. Uygulama tüketiciler için fiyat, görsel özellik karşılaştırması imkânına sahip olduğundan satışı yapan firmalar daha nitelikli müşteriler elde etme imkânına kavuşur. </p>
            <br />
            <p>TaraBul, tüketicinin yararına olarak üretimin, pazarlamanın ve teknik gelişmenin sağlanması için çalışmaktadır. Rekabeti engellemez, bozmaz ya da kısıtlamaz.</p>
            <br />
            <p>12-10/327-97 Rekabet Kurulu kararı dikkate alındığında:</p>
            <br />
            <p>TaraBul fiyat karşılaştırması yapabilen “fiyat karşılaştırma motoru pazarı” uygulamasıdır. </p>
            <br />
            <p>Söz konusu uygulama temel olarak bir ürünü internet üzerinden satış yapan satıcıların / mağazaların, aynı üreticiye ait, aynı marka, aynı birim ürününün fiyatlarını arama motoru olarak derleyip, alışveriş yapanlara tek bir noktadan sunmayı ve böylece kullanıcılara şeffaf ve tarafsız şekilde fiyat karşılaştırma olanağı sağlamayı amaçlamaktadır. Söz konusu uygulama üzerinden herhangi bir satış faaliyeti yapılmamaktadır. Bu kapsamda Pazar da “fiyat karşılaştırma motoru pazarı” olarak tanımlanmıştır. </p>
            <br />
            <p>Tüketici için satışa sunulmuş bir malın 6502 – 28835 sayılı kanunun 6. maddesi uyarınca satıştan kaçınılması yasaklanmıştır. Satışa sunulan bir malın isim tanımlamasının, satış fiyatının, ürün fotoğrafının, görsel özelliklerinin, başlık, firma adı ve ürün özelliklerinin tüketiciye sunulması gerekir. Tüketicinin aynı ürün için farklı satıcılar tarafından sunulan fiyatları öğrenmek belirtilmiş kanunlara göre hakkıdır ve engellenemez. Tüketiciler internet üzerinden bu bilgileri kendileri araştırarak bulabilecekleri gibi, karşılaştırma programlarından da kolayca edinebilirler. Tüketiciyi bilgilendirme amacıyla halka açık olan bu bilgilerin hangi kanallarla halka ulaşacağının kısıtlanması kanunun 6 maddesi uyarınca satıştan kaçınma, 4054 sayılı kanunun 6. Maddesinin ilgili bentlerince rekabetin oluşmasının engellenmesi, tüketicinin zararına olarak üretimin, pazarlamanın ya da teknik gelişmenin kısıtlaması olarak değerlendirilebilir. </p>
            <br />
            <p>TaraBul 6769 sayılı Sınai Mülkiyet kanununa uygun hareket eder. Uygulamamız hukuka aykırı hiçbir içeriği kullanmaz. Hukuka aykırılık tespit edildiğinde içerik ivedilikle uygulamadan kaldırılır.   </p>
            <br />
            <p>6563 sayılı Elektronik Ticaretin Düzenlemesi kanunun ilgili maddelerince ve Yargıtay 11, Hukuk dairesinin 16,12,2019 tarihli E,2019/618 K. 2019/8167 sayılı kararlarında belirtildiği gibi; </p>
            <br />
            <p>“içerik sağlayıcı sıfatı bulunmayan internet yer ve hizmet sağlayıcı kuruluşların tazminat sorumluluğundan bahsedebilmek için, imkan sağladıkları içeriğin hukuka aykırı ve marka hakkına tecavüz niteliğinde olduğunun bilmeleri gerekir.</p>
            <br />
            <p>Ayrıca hak sahiplerinin, içeriğinin kaldırılmasını talep ederken, kendilerinin önceden elde edilmiş üstün hak sahibi olduklarını yaklaşık ispata yeterli delillerini de anılan kuruluşlara sunmuş olmaları gerekir.”</p>
            <br />
        </div>
    )
}
