import './App.css';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import Home from 'domain/home/Home';
import KVKK from 'domain/kvkk/KVKK';
import PrivacyPolicy from 'domain/pp/PrivacyPolicy';
import TermsOfService from 'domain/tos/TermsOfService';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/privacy-policy" exact component={PrivacyPolicy} />
        <Route path="/terms-of-service" exact component={TermsOfService} />
        <Route path="/kvkk" exact component={KVKK} />
      </Switch>
    </Router>

  );
}

export default App;
