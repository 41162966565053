import Info from 'components/info/Info'
import React from 'react'
import "./PrivacyPolicy.css"

export default function PrivacyPolicy() {
    return (
        <div className="privacy">
            <h1>TaraBul Gizlilik Politikası</h1>
            <br />
            <br />
            <Info />
            <br />
            <br />
            <h3>Sözleşme İçeriği:</h3>
            <br />
            <p>www.tarabulapp.com ve TaraBul uygulaması (bundan sonra “Platform” olarak anılacaktır) tüm hakları TaraBul’a (kısaca “TaraBul” olarak anılacaktır) aittir. TaraBul, Platform üzerinden kullanıcılar tarafından kendisine elektronik ortamda iletilen kişisel bilgileri, kullanıcıları ile yaptığı sözleşmeler ile belirlenen amaçlar ve kapsam dışında üçüncü kişilere açıklamayacaktır.</p>
            <br />
            <p>Kullanıcı, TaraBul, tarafından sunulan hizmetlerden faydalandığında; bu gizlilik sözleşmesindeki esasları okuduğunu, kişisel verilerin gizliliği ve bütünlüğü ile ilgili konularda aydınlatıldığını, işbu gizlilik prosedürünün ihlali durumunda hukuki ve cezai sorumluluğun kullanıcıya ait olduğunu kabul etmiş sayılır.</p>
            <br />
            <p>TaraBul, gerektiğinde kullanıcıların IP adreslerini tespit etmekte ve bunu kullanmaktadır. IP adresleri, kullanıcıları genel bir şekilde tanımlamak ve kapsamlı demografik bilgi toplamak amacıyla da kullanılabilir.</p>
            <br />
            <p>TaraBul, Platform dahilinde başka sitelere link verebilir. TaraBul, link vasıtasıyla erişilen sitelerin gizlilik uygulamaları ve içeriklerine yönelik herhangi bir sorumluluk taşımamaktadır.</p>
            <br />
            <p>TaraBul, işbu gizlilik politikasında aksi belirtilmedikçe kişisel bilgilerden herhangi birini TaraBul’un iş birliği içinde olmadığı şirketlere ve üçüncü kişilere açıklamayacaktır. Aşağıda belirtilen sınırlı durumlarda TaraBul, işbu “Gizlilik Politikası” hükümleri dışında kullanıcılara ait bilgileri üçüncü kişilere açıklayabilir. Bu durumlar sınırlı sayıda olmak üzere;</p>
            <br />
            <p>A. Yetkili hukuki otoriteler tarafından çıkarılan ve yürürlükte olan Kanun, Kanun Hükmünde Kararname, Yönetmelik vb. gibi hukuk kurallarının getirdiği zorunluluklara uyulmasının;</p>
            <p>B. TaraBul’un üyeleri ve kullanıcıları ile yaptığı sözleşmelerin gereklerinin yerine getirilmesi ve bunların uygulamaya konulmasının;</p>
            <p>C. Yetkili idari ve adli otorite tarafından usulüne göre yürütülen bir araştırma veya soruşturmanın yürütümü amacıyla kullanıcılarla ilgili bilgi talep edilmesinin ve</p>
            <p>D. Kullanıcıların hakları veya güvenliklerini korumak için bilgi verilmesinin gerekli olduğu hallerdir.</p>
            <br />
            <p>TaraBul, kişisel bilgileri kesinlikle özel ve gizli tutmayı; bunu bir sır saklama yükümlülüğü olarak addetmeyi ve gizliliğin sağlanması ve sürdürülmesi, gizli bilginin tamamının veya herhangi bir kısmının kamu alanına girmesini veya yetkisiz kullanımını veya üçüncü bir kişiye ifşasını önlemek için gerekli tedbirleri almayı ve gerekli özeni göstermeyi taahhüt etmektedir. TaraBul’un gerekli bilgi güvenliği önlemlerini almasına karşın, Platform’a ve sisteme yapılan saldırılar sonucunda gizli bilgilerin zarar görmesi veya üçüncü kişilerin eline geçmesi durumunda, TaraBul’un herhangi bir sorumluluğu olmayacaktır.</p>
            <br />
            <p>TaraBul, işbu Gizlilik Politikası hükümlerini, dilediği zaman Platform’da yayınlamak suretiyle, tek taraflı olarak değiştirebilir. TaraBul’un değişiklik yaptığı Gizlilik Politikası hükümleri, Platform’da yayınlandığı tarih itibariyle yürürlüğe girer.</p>
            <br />
            <p>İletişim için:</p>
            <p>tarabulapp@gmail.com mail adresine ulaşabilirsiniz.</p>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
        </div>
    )
}
