import React from 'react'
import { useHistory } from "react-router-dom";
import "./Home.css"

export default function Home() {
  const history = useHistory()

  return (
    <div className="App">
      <div className="AppWrapper">
        <a className="AppLink" href="https://play.google.com/store/apps/details?id=com.tarabul">
          <img src="/logo_transparent.png" className="AppLogo" alt="logo" />
        </a>
        <a className="AppLink" href="https://play.google.com/store/apps/details?id=com.tarabul">
          <h2 className="AppDescription">
            Alışveriş yaparken ihtiyacınız olan tek uygulama: TaraBul
          </h2>
        </a>
        <a className="AppLink" href="https://play.google.com/store/apps/details?id=com.tarabul">
          <h3 className="AppStore">
            Google Play'de İndir! Yakında Apple Store'da!
          </h3>
        </a>
        <div className="legal">
          <h4 className="legalLink" onClick={() => { history.push("/privacy-policy") }}>
            Gizlilik Politikası
          </h4>
          <h4 className="legalLink" onClick={() => { history.push("/terms-of-service") }}>
            Kullanım Koşulları
          </h4>
          <h4 className="legalLink" onClick={() => { history.push("/kvkk") }}>
            KVKK
          </h4>
        </div>
        <h3 className="description">TaraBul, 4054 sayılı Rekabetin Korunması kanunu ve 6502 sayılı Tüketicinin Korunması kanunu kapsamında, tüketici haklarını korumak amacı ile halkı bilgilendirmek üzere hizmet vermektedir.</h3>
      </div>
    </div>
  )
}
