import Info from 'components/info/Info'
import React from 'react'
import "./KVKK.css"

export default function KVKK() {
    return (
        <div className="kvkk">
            <h1>TaraBul Kişisel Verilerin Korunması Hakkında Aydınlatma Metni</h1>
            <br />
            <br />
            <Info />
            <br />
            <br />
            <h3>Sözleşme İçeriği:</h3>
            <br />
            <p>www.tarabulapp.com ve TaraBul uygulaması (bundan sonra Platform olarak anılacaktır) TaraBul’a aittir (bundan sonra TaraBul olarak anılacaktır) ve kişisel verilerin gizliliği ve güvenliği için TaraBul’un benimsediği ilkeler, işbu gizlilik politikasında düzenlenmiştir.</p>
            <br />
            <p>TaraBul, 6698 sayılı Kişisel Verilerin Korunması Kanunu kapsamında veri sorumlusu olarak kullanıcılarımızın kişisel verilerini kaydedecek, sınıflandıracak, işleyecek, saklayacak, güncelleyecek ve üçüncü kişilere aktaracak ve açıklayabilecektir. İşbu bilgilendirme ile Kanun hükümleri gereğince karşılıklı hak ve yükümlülükler konusunda tarafları bilgilendiriyoruz.</p>
            <br />
            <p>TaraBul, 6698 sayılı Kişisel Verilerin Korunması Kanununda belirtildiği şekliyle tanımlanmış kullanıcılar tarafından sağlanacak verileri yine aynı kanunda belirtilmiş esaslara uygun şekilde özel ve gizli tutmayı, sır olarak saklamayı taahhüt eder. Bu konuda gerekli tüm teknik, hukuki ve yönetimsel tedbirleri kanuna uygun şekilde almayı ve üzerine düşen özeni tam olarak göstermeyi işbu bildirimle taahhüt eder.</p>
            <br />
            <p>TaraBul hizmetlerini kullanmaya başlayan kişi, Tarabul ile resmi sözleşme yapmış olduğunu, bu sözleşme metnini okuduğunu, sözleşme koşullarını, kurallarını bildiğini ve bu sözleşmeye aykırı hareket etmeyeceğini kabul eder.</p>
            <br />
            <br />
            <h4>1. Toplanan Kişisel Veriler ve Elde Edilme Yöntemleri</h4>
            <br />
            <p>İşbu bildirim kapsamında düzenlenen koşullar kapsamında aşağıdaki bilgiler, ‘Kişisel Veri’ olarak tanımlanmıştır. Kullanıcılar, üyelik girişi esnasında dijital ortamda TaraBul’a aşağıdaki verileri sağlar.</p>
            <br />
            <p>Kullanıcı, kayıt sırasında paylaşmış olduğu bilgileri, dilediği zaman güncelleme, değiştirme ve silme hakkına sahiptir.</p>
            <br />
            <p>•	İsim, soy isim, yaş, cinsiyet, telefon, e-posta ve kullanıcının diğer iletişim bilgileri,</p>
            <p>•	Kullanıcının gezinti tanımlama bilgileri (çerez, web tarayıcı işaretçileri-bilgileri, IP adresi, konum, işletim sistemi, sisteme giriş, görüntülenme süresi, beacon bilgisi)</p>
            <p>•	Sosyal medya mecralarında ve diğer mecralarda oluşan TaraBul ile ilgili yorum, görüş ve yazılar,</p>
            <p>•	TaraBul platformu üzerinde gerçekleştirilen hareketler ve arama geçmişi.</p>
            <br />
            <p>Kullanıcı, TaraBul’a üyelik veya hizmet alma sırasında sağladığı verilerin, bilgilerin doğruluğunu ve güncelliğini taahhüt etmiş sayılır. Kullanıcıdan istenen bilgilerin gerçeğe uygun olarak doldurulması ve ilerde değişiklik olması durumunda bilgilerin güncellenmesidir. </p>
            <br />
            <br />
            <h4>2. Kişisel Verilerinizin İşlenme Amaçları ve Hukuki Sebepleri</h4>
            <br />
            <p>Kişisel verileriniz, sizlere sunacağımız her türlü ürün ve hizmetlerle ilgili olarak gerçekleştirilecek her türlü iş ve işlemde işlem sahibini ve muhatabını belirlemek üzere kimlik bilgileri, adres, vergi numarası ve diğer bilgilerinizin kaydedilmesi, kâğıt üzerinde veya elektronik ortamda gerçekleştirilecek iş ve işlemlere dayanak olacak bilgi ve belgelerin düzenlemesi, ilgili mevzuat uyarınca adli ve idari tüm yetkili mercilerce öngörülen bilgi saklama, raporlama ve bilgilendirme yükümlülüklerine uyulması ve aramızdaki sözleşmelerin ve size vereceğimiz hizmetin gereğinin yerine getirilmesi amaçlarıyla işlenecektir. Kişisel verileriniz aşağıdaki amaçlarla da işlenebilecektir:</p>
            <br />
            <p>•	Kişisel verilerin bulut ortamında üçüncü kişiler vasıtasıyla saklanması, depolanması, güncelleştirilmesi;</p>
            <p>•	Tarafınıza bilgilendirme, reklam, promosyon gibi tarafların yararına olduğu düşünülen araçların ve benzeri araçların kullanılabilmesi için iletilerin gönderilmesinde işbirliği yaptığımız operatör ve diğer üçüncü kişilerle paylaşılması;</p>
            <p>•	Müşterilerin ihtiyaç, öncelik ve kredibiliteleri yönünden sınıflandırılması ve belli bir not sistemine tabi tutulması;</p>
            <br />
            <p>TaraBul ile yaptığınız veya yapacağınız sözleşmelerin kurulması veya ifasıyla doğrudan doğruya ilgisi olması kaydıyla, kişisel verilerinizin işlenmesinin gerekli olması ve sözleşmenin kurulması ve ifası dışındaki konularda sizin açık rızanızdır.</p>
            <br />
            <br />
            <h4>3. Kişisel Verilerinizin Aktarılması</h4>
            <br />
            <p>Kişisel verileriniz, hissedarlarımıza, yurtiçi/yurtdışı iştiraklerimize; faaliyetlerimizi yürütmek üzere hizmet aldığımız, işbirliği yaptığımız, danışmanlarımıza, avukatlarımıza, program ortağı kurum, kuruluş, yurtiçi/yurtdışı şirketlere, verilerin bulut ortamında saklanması hizmeti aldığımız kişi ve kurumlara, müşterilerimize gönderdiğimiz iletilerin gönderilmesi konusunda anlaşmalı olduğumuz kurumlara, işbirliği yaptığımız veya hizmet aldığımız yurtiçi ve yurtdışı bankalar (muhabir bankalar dahil), finansal kuruluşlar, ödeme kuruluşları ve diğer üçüncü kişilere ilgili işbirliklerimiz çerçevesinde aktarılabilecektir. Resmi mercilerin talebi veya mevzuat gereğince bilgi verme zorunluluğu olan hallerde bilgileriniz yasal merci ve kurumlara aktarılabilecek olup, bunlar da bilgilerinizi ayrıca paylaşabilir.</p>
            <br />
            <br />
            <h4>4. Kişisel Verilerin Saklanması ve Güvenliği</h4>
            <p>Kişisel veriler, bu bilgileri verme amacı geçerli olduğu sürece saklanacaktır. Faaliyet konumuzla ilgili ihtiyaçlarınızın belirlenmesi, size daha çabuk hizmet verilmesi amacıyla, verileriniz bizden aldığınız hizmetin süresinin dolmasından sonra da tarafımızdan işlenmeye devam edilecektir. Veriler, yasal sürelere tabi olarak ve yasal merciler ve diğer ilgili kamu otoritelerine raporlama, bilgilendirme amaçları için tutulması gerekiyorsa bu sınırlara uyulacaktır. Saklanan, kaydedilen verilerin kaybolmaması, yetkisiz kişilerin eline geçmemesi ve hukuka aykırı kullanımların önlenmesi için gerekli güvenlik önlemleri tarafımızdan alınacaktır.</p>
            <br />
            <br />
            <h4>5. Verilerin Doğruluğu</h4>
            <br />
            <p>Kullanıcılar, sisteme üye olurken doğru ve güncel bilgiler ile kaydolmanın zorunlu olduğunu kabul eder. Değişiklik olduğu takdirde, bu bilgilerin kullanıcılar tarafından güncellenmesi gerekir. Aksi halde, doğacak zararlardan TaraBul sorumlu tutulamaz.</p>
            <br />
            <br />
            <h4>6. Sistem Güvenliği</h4>
            <br />
            <p>TaraBul, internet portalının kullanımından kaynaklanabilecek doğrudan ve/veya dolaylı maddi ve/veya manevi zararlardan hiçbir şekilde sorumlu değildir. TaraBul virüs ve benzeri amaçlı yazılımlardan arındırılmış olması için gereken tedbiri almıştır. Ancak bunun yanı sıra kullanıcıların, nihai güvenliği sağlaması için kendi virüs koruma sistemini tedarik etmesi ve gerekli korumayı sağlaması gerekmektedir. TaraBul’u sistemine yüklemiş kullanıcı, kendi yazılım ve işletim sistemlerinde oluşabilecek tüm hata ve bunların doğrudan ya da dolaylı sonuçlarından veya veri kayıplarından kullanıcının kendisinin sorumlu olduğunu kabul etmiş sayılır.</p>
            <br />
            <br />
            <h4>7. Üçüncü Sitelere Bağlantı Verilmesi</h4>
            <br />
            <p>TaraBul tarafından işletilmeyen diğer web sitelerine bağlantılar, reklam veya yönlendirmeler ziyaret edildiğinde, o sitenin güvenlik politikalarından, kullanım koşullarından ve sitenin uygulamada olan hizmetlerinden TaraBul sorumlu değildir.</p>
            <br />
            <br />
            <h4>8. Şifrenin Güvenliği ve Sorumluluk</h4>
            <br />
            <p>TaraBul uygulamasına giriş yapabilmeniz için kayıt sırasında bir şifre oluşturmanız istenmemektedir. Eğer ilerde böyle bir gereklilik haiz olursa oluşturulacak şifrenin sorumluluğu kullanıcıya aittir. Kullanıcı şifresi TaraBul sistemlerinde kaydedilmemektedir. Şifrenin yalnızca kullanıcı tarafından bilinmesi gerekmektedir. Şifrenin 3. kişilerin eline geçmesi durumunda TaraBul sorumluluk kabul etmez.</p>
            <br />
            <br />
            <h4>9. Sözleşmede ve Sistem Özelliklerinde Değişiklik</h4>
            <br />
            <p>TaraBul, Platform'da yer alan bütün ürün ve hizmetleri, Platform kullanımı ücretlendirmesini, bilgileri, görsel unsurları, önceden bildirimde bulunmaksızın değiştirme, kullanımını askıya alma veya durdurma hakkını saklı tutar.</p>
            <br />
            <p>TaraBul, işbu sözleşme üzerinde değişiklik ve güncelleme yapma hakkını saklı tutar. Böyle bir durumda sitede yayınlanmakla yürürlüğe girer. Sözleşmenin güncel halini takip etmek, kullanıcının sorumluluğundadır.</p>
            <br />
            <p>TaraBul üye kaydı sırasında tek taraflı inisiyatife sahiptir. Siteye dilediği kişi veya firmaların üye olarak kabul edilmemesine ve gerekli gördüğünde üyelikten çıkarılmasına dair tek taraflı karar verme hakkını mahfuz tutmaktadır. TaraBul tarafından uygun görülmeyen kullanıcıların hesapları haber verilmeksizin kapatılabilir veya üyelikleri askıya alınabilir.</p>
            <br />
            <br />
            <h4>10. Garanti Etme</h4>
            <br />
            <p>TaraBul, servisleri olduğu gibi sunar ve her koşulda sorunsuz çalışacağını taahhüt etmez, servislerde oluşabilecek hatalardan sorumlu değildir. Sistem üzerinde yapılacak herhangi bir değişiklik, askıya alma, sona erme veya sadece sistemin kullanımı sebebiyle oluşabilecek veri kayıplarından TaraBul sorumlu değildir.</p>
            <br />
            <br />
            <br />
            <p>İletişim için:</p>
            <p>tarabulapp@gmail.com mail adresine ulaşabilirsiniz.</p>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
        </div>
    )
}
