import Info from 'components/info/Info'
import React from 'react'
import "./TermsOfService.css"

export default function TermsOfService() {
    return (
        <div className="terms">
            <h1>TaraBul Kullanım Koşulları</h1>
            <br />
            <br />
            <Info />
            <br />
            <br />
            <h3>Sözleşme İçeriği:</h3>
            <br />
            <h4>1. Tanımlar</h4>
            <br />
            <p><strong>TaraBul:</strong> TaraBul</p>
            <br />
            <p><strong>Kullanıcı:</strong> İnternet yoluyla veya mobil olarak tarabulapp.com hizmetlerine erişen, bilgilerini kullanan ve paylaşan, bu bilgilere dayalı olarak işlem yapan gerçek veya tüzel kişilerdir.</p>
            <br />
            <p><strong>Platform:</strong> www.tarabulapp.com isimli alan adından, bu alan adına bağlı alt alan adlarından oluşan web sitesi ve mobil platformdaki varlığı.</p>
            <br />
            <p><strong>TaraBul Hizmetleri (Kısaca "Hizmet"):</strong> Kullanıcıların Kullanım Sözleşmesi'nde tanımlı olan iş ve işlemlerini gerçekleştirmelerini sağlamak amacıyla, tarabulapp.com tarafından Platform içerisinde ortaya konulan uygulamalar.</p>
            <br />
            <p><strong>Satıcı:</strong> Çeşitli mal ve hizmetlerin satışı işiyle uğraşan firmalar, marketler, market zincirleri, aracı firmalar.</p>
            <br />
            <p><strong>Yer Sağlayıcı:</strong> Hizmet ve içerikleri barındıran sistemleri sağlayan veya işleten gerçek veya tüzel kişiler</p>
            <br />
            <br />
            <h4>2. Taraflar</h4>
            <br />
            <p>İşbu sözleşme ve sözleşmeyle atıfta bulunulan ve sözleşmenin ayrılmaz birer parçası olan eklerden (Gizlilik Politikası) oluşan işbu tarabulapp.com Kullanım Sözleşmesi (bundan böyle "Kullanım Sözleşmesi" olarak anılacaktır) ile tarabulapp.com sitesine işbu Kullanım Sözleşmesi'nde belirtilen koşullar dahilinde tarabulapp.com hizmetlerinden yararlanan Kullanıcılar arasında, Kullanıcının Platformu kullanmaya başlayarak elektronik olarak onay vermesi ile karşılıklı olarak kabul edilerek ve 4. maddedeki şarta bağlı olarak yürürlüğe girmiştir.</p>
            <br />
            <p>Kullanıcı, işbu Kullanım Sözleşmesini kabul etmeden önce, https://www.tarabulapp.com/terms-of-service bağlantısından ulaşılan ve indirilebilen bilgilendirme metnini okuyup incelediğini, şartlarını anladığını, bilgilendirme metnini bilgisayarına indirebildiğini ve sözleşme öncesi bilgilendirme yükümlülüğünün kendisine karşı tam olarak yerine getirildiğini, sözleşme ile ilgili ön bilgileri edindiğini teyit ettiğini beyan ve kabul eder.</p>
            <br />
            <p>Kullanıcı, Kullanım Sözleşmesi sürecine ilişkin işlemleri tamamlayarak işbu Kullanım Sözleşmesinin tamamını okuduğunu, içeriğini tamamen anladığını, kendisi ile ilgili olarak verdiği bilgilerin doğru ve güncel olduğunu ve Kullanım Sözleşmesinde belirtilen ve Platform’ da da yer alan işbu Kullanım Sözleşmesi konusu ile ilgili tüm hususları kayıtsız ve şartsız olarak kabul ettiğini ve onayladığını, bu hususlar ile ilgili olarak herhangi bir itiraz ve defi ileri sürmeyeceğini şimdiden beyan, kabul ve taahhüt eder. Kullanıcı, Kullanım Sözleşmesi konusunu oluşturan hizmetin içeriği, esaslı nitelikleri, özellikleri, ücret talep edilmediği ile ilgili bilgileri okuyup hizmetler hakkında gerekli tüm bilgilere sahip olduğunu, bu bilgiler doğrultusunda elektronik ortamda Kullanım Sözleşmesine ilişkin gerekli onayı verdiğini beyan, kabul ve taahhüt eder.</p>
            <br />
            <p>TaraBul’ un 6698 sayılı Kişisel Verilerin Korunması Kanunu kapsamında hazırladığı, kişisel verilerin işlenmesi ile ilgili aydınlatma metni Platform’ da yayınlanmaktadır. Kullanıcı, anılan aydınlatma metnini ve gizlilik politikasını okuyup incelediğini, TaraBul’ un ilgili kanun kapsamındaki aydınlatma yükümlülüğünü kendisine karşı yerine getirdiğini beyan ve kabul eder.</p>
            <br />
            <br />
            <h4>3. Sözleşmenin Konusu ve Kapsamı</h4>
            <br />
            <p>Kullanım Sözleşmesi'nin konusu, Platform’ da sunulan Hizmetlerin, bu Hizmetlerden yararlanma şartlarının ve tarafların hak ve yükümlülüklerinin tespitidir. Kullanım Sözleşmesi'nin hükümlerini kabul etmekle, Platform içinde yer alan, kullanım kurallarını ve Hizmetlere ilişkin olarak TaraBul tarafından açıklanan her türlü usul, kural ve beyanı da kabul etmiş olmaktadır. Kullanıcı, bahsi geçen usul, kural ve beyanlarda belirtilen her türlü hususa uygun olarak davranacağını kabul, beyan ve taahhüt eder.</p>
            <br />
            <br />
            <h4>4. Üyelik ve Hizmet Kullanımı Şartları</h4>
            <br />
            <p>Platform, internet üzerinden çeşitli ürünlerin fiyat ve diğer özelliklerinin karşılaştırılması hizmeti verilen elektronik bir platformdur. Platform hiçbir ürünün satıcısı veya üreticisi değildir, ürün satışı veya üretimi ile iştigal etmemektedir. Platform, içeriğinde yer alan hiçbir ürünün satışı yapmamaktadır. Platform, tüketicilere ürün veya hizmet satışı zincirinin hiçbir aşamasında yer almamaktadır. TaraBul, Kullanıcı ile Satıcılar arasındaki ilişkiler nedeniyle hiçbir şekilde sorumlu tutulamaz. TaraBul, hiçbir şekilde, Satıcıların Kullanıcı ile sözleşme yapacaklarını, iyi niyetli olduklarını, borçlarını ifa kabiliyetlerinin varlığını, borçlarının ifasını, beyanlarının doğruluğunu, işlem yapmaya yetkili olduklarını, sağlanan mal ve hizmetlerin ayıpsız olacağını ve benzer diğer hususları garanti etmez. TaraBul, Kullanıcının Platform vasıtasıyla ulaştığı Satıcılarla gireceği hukuki ilişkilere tamamen yabancıdır ve bunlara ilişkin hiçbir sorumluluğu yoktur. Sorumluluğun tamamı işlemin tarafı olan Satıcılara ve Kullanıcıya aittir. 5651 sayılı yasa kapsamında Yer Sağlayıcı statüsünde olan TaraBul, Satıcılar tarafından sağlanan içeriği kontrol etmek veya hukuka aykırı bir faaliyetin olup olmadığını araştırmakla yükümlü değildir.</p>
            <br />
            <p>Üyelik, Platformu kullanmak için gerekli bilgilerin (isim, soy isim ve e-posta) gönderilmesi suretiyle kayıt işleminin yaptırılması ve TaraBul tarafından kayıt işleminin onaylanması ile tamamlanır. Üyelik işlemi tamamlanmadan, işbu sözleşmede tanımlanan Kullanıcı olma hak ve yetkisine sahip olunamaz.</p>
            <br />
            <p>Kullanıcının Hizmetlerden yararlanması ücretsizdir. TaraBul, ilerde Hizmetleri ücretli hale getirme ve/veya ücret karşılığı Hizmetler ilave etme hakkını saklı tutar.</p>
            <br />
            <br />
            <h4>5. Tarafların Hak ve Yükümlülükleri</h4>
            <br />
            <p>5.1 Kullanıcı, üyelik prosedürlerini yerine getirirken, Platformun Hizmetlerinden faydalanırken ve Platformdaki Hizmetlerle ilgili herhangi bir işlemi yerine getirirken, Kullanım Sözleşmesi’nde yer alan tüm şartlara, Platformun ilgili yerlerinde belirtilen kurallara ve yürürlükteki tüm mevzuata uygun hareket edeceğini, yukarıda belirtilen tüm şart ve kuralları anladığını ve onayladığını kabul, beyan ve taahhüt eder.</p>
            <br />
            <p>5.2 Kullanıcı, yürürlükteki emredici mevzuat hükümleri gereğince veya diğer Kullanıcılar ile üçüncü şahısların haklarının ihlal edildiğinin iddia edilmesi durumlarında, TaraBul’ un kendisine ait gizli/özel/ticari bilgileri gerek resmi makamlara ve gerekse hak sahibi kişilere açıklamaya yetkili olacağını ve bu sebeple TaraBuldan her ne nam altında olursa olsun dava açamayacağını, tazminat talep edilemeyeceğini kabul, beyan ve taahhüt eder.</p>
            <br />
            <p>5.3 Kullanıcıların TaraBul tarafından sunulan Hizmetlerden yararlanabilmek amacıyla kullandıkları sisteme erişim araçlarının (Kullanıcı ismi, şifre vb.) güvenliği, saklanması, üçüncü kişilerin bilgisinden uzak tutulması ve kullanılması durumlarıyla ilgili hususlar tamamen kullanıcıların sorumluluğundadır. kullanıcıların, sisteme giriş araçlarının güvenliği, saklanması, üçüncü kişilerin bilgisinden uzak tutulması, kullanılması gibi hususlardaki tüm ihmal ve kusurlarından dolayı kullanıcıların ve/veya üçüncü kişilerin uğradığı veya uğrayabileceği zararlara istinaden TaraBul’ un, doğrudan veya dolaylı, herhangi bir sorumluluğu yoktur.</p>
            <br />
            <p>5.4 Kullanıcılar, TaraBul’un yazılı onayı olmadan, Kullanım Sözleşmesi kapsamındaki hak ve yükümlülüklerini, kısmen veya tamamen, herhangi bir üçüncü kişiye devredemezler.</p>
            <br />
            <p>5.5 TaraBul, Kullanıcı’ların Platform’da listelenmesine yer verilen ürün açıklamalarına ulaşmasını ve ürünlerin renk, model, cins, vasıf, nitelik ve/veya özellikleri ile ilgili her türlü karşılaştırılmanın yapılmasını sağlayacaktır. TaraBul, Kullanıcı’ların Kullanım Sözleşmesi’nde tanımlı olan iş ve işlemleri daha etkin şekilde gerçekleştirebilmelerini sağlamak üzere, dilediği zaman Hizmet’lerinde değişiklikler ve/veya uyarlamalar yapabileceği gibi ek Hizmet’ler de sunabilir. TaraBul tarafından yapılan bu değişiklikler ve/veya uyarlamalar ve/veya eklenen yeni Hizmet’ler TaraBul tarafından, ilgili Hizmet’in kullanımıyla ilgili açıklamaların bulunduğu web sayfasından Kullanıcı’lara duyurulur.</p>
            <br />
            <p>5.6 TaraBul’un sunduğu Hizmet’lerden yararlananlar ve Platform’u kullananlar, yalnızca hukuka uygun amaçlarla Platform üzerinde işlem yapabilirler. Kullanıcı’ların, Platform dahilinde yaptığı her işlem ve eylemdeki hukuki ve cezai sorumluluk kendilerine aittir. Her Kullanıcı, TaraBul ve/veya başka bir üçüncü şahsın ayni veya şahsi haklarına veya malvarlığına tecavüz teşkil edecek şekilde, Platform dahilinde bulunan resimleri, metinleri, görsel ve işitsel imgeleri, video kliplerini, dosyaları, veri tabanlarını, katalogları ve listeleri çoğaltmayacağını, kopyalamayacağını, dağıtmayacağını, işlemeyeceğini, gerek bu eylemleri ile gerekse de başka yollarla TaraBul ile doğrudan ve/veya dolaylı olarak rekabete girmeyeceğini kabul, beyan ve taahhüt eder. TaraBul, Kullanıcı’ların Kullanım Sözleşmesi hükümlerine ve/veya hukuka aykırı olarak Platform üzerinde gerçekleştirdikleri faaliyetler nedeniyle üçüncü kişilerin uğradıkları veya uğrayabilecekleri zararlardan doğrudan ve/veya dolaylı olarak, hiçbir şekilde sorumlu tutulamaz.</p>
            <br />
            <p>5.7 TaraBul, Platform’da sunulan Hizmetler’i ve içerikleri her zaman değiştirebilme; işbu içerikleri Kullanıcı’lar ve Platform’u kullananlar da dahil olmak üzere, üçüncü kişilerin erişimine kapatabilme ve silme hakkını saklı tutmaktadır. TaraBul, bu hakkını hiçbir bildirimde bulunmadan ve önel vermeden kullanabilir.</p>
            <br />
            <p>5.8 TaraBul, Platform’da yer alan Kullanıcı bilgilerini, üye güvenliği, kendi yükümlülüğünü ifa ve bazı istatistiki değerlendirmeler için dilediği biçimde kullanabilir. Bunları bir veri tabanı üzerinde tasnif edip muhafaza edebilir.</p>
            <br />
            <p>5.9 TaraBul, Kullanıcı'lar arasında site üzerinden gerçekleşen ve Platform’un işleyişine ve/veya Kullanım Sözleşmesi'ne ve/veya Platform'un genel kurallarına ve/veya genel ahlak kurallarına aykırı ve TaraBul tarafından kabul edilmesi mümkün olmayan mesajların ve/veya içeriklerin tespit edilmesi amacıyla gerekli içerik ve/veya mesaj taraması yapabilir ve tespit ettiği mesaj ve/veya içerikleri istediği zaman ve şekilde erişimden kaldırabilir; TaraBul, bu mesaj ve/veya içeriği oluşturan Kullanıcı'yı yazılı uyarabilir ve/veya Kullanıcı'nın üyeliğine, herhangi bir ihbar yapmadan, geçici veya kalıcı olarak, son verebilir.</p>
            <br />
            <p>5.10 Kullanıcılar; Platform üzerinden erişim verilen ürünlerin satışının gerçekleşeceği Satıcılar’a ait internet sitelerinde, Satıcılar tarafından satışa arz edilen ürün/ürünler ve/veya yayınlanan içerik/içeriklerden dolayı TaraBul’un, TaraBul çalışanlarının ve yöneticilerinin hiçbir şekilde sorumluluğu bulunmadığını kabul, beyan ve taahhüt eder.</p>
            <br />
            <p>5.11. TaraBul tüketiciler için ücretsiz bir bilgi servisidir. Alışveriş öncesi sipariş edilecek ürünle ilgili geçerli bilgi her zaman için ilgili mağazanın web sitesinden alınmalıdır. TaraBul’da verilen bilgilerdeki hatalardan, eksikliklerden veya bu bilgilere dayanılarak yapılan işlemler sonucu meydana gelebilecek her türlü maddi ve manevi zararlardan ve her ne şekilde olursa olsun üçüncü kişilerin uğrayabileceği her türlü zararlardan dolayı TaraBul sorumlu tutulamaz.</p>
            <br />
            <p>5.12 Kullanıcılar; Platform’a üye olarak sitenin bilgilendirme amaçlı e-posta göndermesini kabul eder.</p>
            <br />
            <br />
            <h4>6. Gizlilik Politikası</h4>
            <br />
            <p>TaraBul, Kullanıcı’lara ilişkin bilgileri, işbu sözleşmenin Gizlilik Politikası bölümündeki düzenlemelere uygun olarak kullanabilir. TaraBul, Kullanıcı’lara ait gizli bilgileri, Kullanım Sözleşmesi’nde aksine müsaade edilen durumlar haricinde, üçüncü kişi ve kurumlara kullandırmaz.</p>
            <br />
            <br />
            <h4>7. Diğer Hükümler</h4>
            <br />
            <p>7.1. Fikri Mülkiyet Hakları</p>
            <br />
            <p>a) Platform’un tasarım, metin, image, html kodu ve diğer kodlar da dahil ve fakat bunlarla sınırlı olmamak kaydıyla tüm unsurları (bundan böyle “TaraBul’un Telif Haklarına Tabi Çalışmalar” olarak anılacaktır) TaraBul’a aittir ve/veya TaraBul tarafından üçüncü bir kişiden alınan lisans hakkı altında kullanılmaktadır. Kullanıcı’lar, Hizmetler’i, TaraBul bilgilerini ve TaraBul’un Telif Haklarına Tabi Çalışmalarını yeniden satamaz, paylaşamaz, dağıtamaz, sergileyemez veya başkasının Hizmetler’e erişmesine veya kullanmasına izin veremez. Aksi takdirde, lisans verenler de dahil üçüncü kişilerin ve TaraBul’un uğradıkları zararları ve/veya bu zararlardan dolayı TaraBul’dan talep edilen tazminat miktarını, mahkeme masrafları ve avukatlık ücreti de dahil olmak üzere, karşılamakla yükümlü olacaklardır. Kullanıcılar, TaraBul’ un Telif Haklarına Tabi Çalışmalarını çoğaltamaz, dağıtamaz veya bunlardan türemiş çalışmalar yapamaz veya hazırlayamaz.</p>
            <br />
            <p>b) TaraBul’ un, Hizmetler’i, bilgileri, TaraBul Telif Haklarına Tabi Çalışmaları, ticari markaları, ticari görünümü veya Platform vasıtasıyla sahip olduğu her tür maddi ve fikri mülkiyet hakları da dâhil tüm malvarlığı, ayni ve şahsi hakları, ticari bilgi ve know-how' a yönelik tüm hakları saklıdır.</p>
            <br />
            <p>c) TaraBul da yer alan bütün marka ve isimlerin hakları ilgili firmalara aittir. TaraBul, Platform içeriğini oluştururken diğer taraflara ait fikri mülkiyet haklarını gözetir ve bu tür içerikleri kullanmaz, yayınlamaz. Değişik teknik tedbirlere rağmen TaraBul’ da yayınlanan bir içerikle ilgili herhangi bir fikri mülkiyet hakkı ihlali olduğu düşünülen durumlarda tarabulapp@gmail.com e-posta adresine bilgi verilmelidir. Söz konusu hak sahiplerinin, içeriğin kaldırılmasını talep ederken, kendilerinin önceden elde edilmiş üstün hak sahibi olduklarını, ispata yeterli delilleri ile tarafımıza sunmuş olmaları gerekir. TaraBul ivedilikle gerekli incelemeyi ve uygun düzenlemeyi yapmayı taahhüt eder.</p>
            <br />
            <p>d) TaraBul, 4054 sayılı Rekabet kanunu ve 6502 sayılı Tüketicinin Korunması kanunu kapsamında, müşteri odaklı en iyi içeriği sunma ilkesine bağlı kalarak, tüketici haklarını korumak, tüketiciyi bilgilendirmek, daha bilinçli satın alma yapmasına destek olacak bütün ürün bilgilerini tüketiciye sunmak amacı ile kullanıma sunulmuş, fiyat karşılaştırması yapabilen “fiyat karşılaştırma motoru pazarı” uygulamasıdır.</p>
            <br />
            <p>TaraBul uygulamasında tüketici, fiyat, görsel özellik karşılaştırması yapabilmekte, talep etiği ürünleri farklı kriterlere göre arayabilmekte ve sıralamaya tabi tutabilmektedir. Tüketici uygun bulduğu ürünü satan firmaya kolayca ulaşabilir. Bu sayede uygulama fiyat rekabetinin de gerçekleştirmesine olanak sağlar.</p>
            <br />
            <p>Uygulama Firmalar/Satıcılar/Mağazalar için internet üzerinde ve pazarda daha fazla dönüşümlü potansiyel trafik oluşturmakta, sistemde daha geniş görünürlük sağlamaktadır. Uygulama tüketiciler için fiyat, görsel özellik karşılaştırması imkânına sahip olduğundan satışı yapan firmalar daha nitelikli müşteriler elde etme imkânına kavuşur. </p>
            <br />
            <p>TaraBul, tüketicinin yararına olarak üretimin, pazarlamanın ve teknik gelişmenin sağlanması için çalışmaktadır. Rekabeti engellemez, bozmaz ya da kısıtlamaz.</p>
            <br />
            <p>12-10/327-97 Rekabet Kurulu kararı dikkate alındığında, TaraBul fiyat karşılaştırması yapabilen “fiyat karşılaştırma motoru pazarı” uygulamasıdır. </p>
            <br />
            <p>Söz konusu uygulama temel olarak bir ürünü internet üzerinden satış yapan satıcıların / mağazaların, aynı üreticiye ait, aynı marka, aynı birim ürününün fiyatlarını arama motoru olarak derleyip, alışveriş yapanlara tek bir noktadan sunmayı ve böylece kullanıcılara şeffaf ve tarafsız şekilde fiyat karşılaştırma olanağı sağlamayı amaçlamaktadır. Söz konusu uygulama üzerinden herhangi bir satış faaliyeti yapılmamaktadır. Bu kapsamda Pazar da “fiyat karşılaştırma motoru pazarı” olarak tanımlanmıştır. </p>
            <br />
            <p>Tüketici için satışa sunulmuş bir malın 6502 – 28835 sayılı kanunun 6. maddesi uyarınca satıştan kaçınılması yasaklanmıştır. Satışa sunulan bir malın isim tanımlamasının, satış fiyatının, ürün fotoğrafının, görsel özelliklerinin, başlık, firma adı ve ürün özelliklerinin tüketiciye sunulması gerekir. Tüketicinin aynı ürün için farklı satıcılar tarafından sunulan fiyatları öğrenmek belirtilmiş kanunlara göre hakkıdır ve engellenemez. Tüketiciler internet üzerinden bu bilgileri kendileri araştırarak bulabilecekleri gibi, karşılaştırma programlarından da kolayca edinebilirler. Tüketiciyi bilgilendirme amacıyla halka açık olan bu bilgilerin hangi kanallarla halka ulaşacağının kısıtlanması kanunun 6 maddesi uyarınca satıştan kaçınma, 4054 sayılı kanunun 6. Maddesinin ilgili bentlerince rekabetin oluşmasının engellenmesi, tüketicinin zararına olarak üretimin, pazarlamanın ya da teknik gelişmenin kısıtlaması olarak değerlendirilebilir. </p>
            <br />
            <p>TaraBul 6769 sayılı Sınai Mülkiyet kanununa uygun hareket eder. Uygulamamız hukuka aykırı hiçbir içeriği kullanmaz. Hukuka aykırılık tespit edildiğinde içerik ivedilikle uygulamadan kaldırılır.   </p>
            <br />
            <p>6563 sayılı Elektronik Ticaretin Düzenlemesi kanunun ilgili maddelerince ve Yargıtay 11, Hukuk dairesinin 16,12,2019 tarihli E,2019/618 K. 2019/8167 sayılı kararlarında belirtildiği gibi; </p>
            <br />
            <p>“içerik sağlayıcı sıfatı bulunmayan internet yer ve hizmet sağlayıcı kuruluşların tazminat sorumluluğundan bahsedebilmek için, imkan sağladıkları içeriğin hukuka aykırı ve marka hakkına tecavüz niteliğinde olduğunun bilmeleri gerekir.</p>
            <br />
            <p>Ayrıca hak sahiplerinin, içeriğinin kaldırılmasını talep ederken, kendilerinin önceden elde edilmiş üstün hak sahibi olduklarını yaklaşık ispata yeterli delillerini de anılan kuruluşlara sunmuş olmaları gerekir.”</p>
            <br />
            <p>Yukarıda belirtilen haller dışında herhangi bir fikri mülkiyet hakki, is birliği, sponsorluk ve bunun gibi gibi durumlarda lütfen bizimle iletişime geçin. Tarabul gerekli işlemleri ivedilikle yapmayı taahhüt eder.</p>
            <br />
            <br />
            <p>7.2. Sözleşme Değişiklikleri</p>
            <br />
            <p>TaraBul, tamamen kendi takdirine bağlı ve tek taraflı olarak, işbu Kullanım Sözleşmesi’ni, uygun göreceği herhangi bir zamanda, Platform da ilan etmek suretiyle değiştirebilir. İşbu Kullanım Sözleşmesi’nin değişen hükümleri, ilan edildikleri tarihte geçerlilik kazanacak; geri kalan hükümler, aynen yürürlükte kalarak hüküm ve sonuçlarını doğurmaya devam edecektir. İşbu Kullanım Sözleşmesi, Kullanıcının tek taraflı beyanları ile değiştirilemez.</p>
            <br />
            <br />
            <p>7.3. Mücbir Sebepler</p>
            <br />
            <p>Hukuken mücbir sebep sayılan tüm durumlarda, TaraBul, işbu sözleşme ile belirlenen edimlerinden herhangi birini geç veya eksik ifa etme veya ifa etmeme nedeniyle yükümlü değildir. Bu ve bunun gibi durumlar, TaraBul için, gecikme, eksik ifa etme veya ifa etmeme veya temerrüt addedilmeyecek veya bu durumlar için TaraBul'dan herhangi bir nam altında tazminat talep edilemeyecektir. "Mücbir sebep" terimi, doğal afet, isyan, savaş, grev, iletişim sorunları, altyapı ve internet arızaları, elektrik kesintisi ve kötü hava koşulları da dahil ve fakat bunlarla sınırlı olmamak kaydıyla, ilgili tarafın makul kontrolü haricinde ve TaraBul’un gerekli özeni göstermesine rağmen önleyemediği, kaçınılamayacak olaylar olarak yorumlanacaktır.</p>
            <br />
            <br />
            <p>7.4. Sözleşmenin Feshi</p>
            <br />
            <p>İşbu Kullanım Sözleşmesi, süresiz olarak düzenlenmiştir. TaraBul herhangi bir süre tayinine gerek olmaksızın istediği zaman sözleşmeyi fesih hakkına sahiptir.</p>
            <br />
            <p>TaraBul, kullanıcıların işbu Kullanım Sözleşmesi’ni ve/veya, Platform içinde yer alan kullanıma, üyeliğe ve Hizmetlere ilişkin benzeri kuralları ihlal etmeleri durumunda ve özellikle aşağıda sayılan hallerde, sözleşmeyi tek taraflı olarak feshedebilecek ve Kullanıcılar, fesih sebebiyle, TaraBul’ un uğradığı tüm zararları tazmin etmekle yükümlü olacaktır:</p>
            <br />
            <p>a) Kullanıcının, herhangi bir yöntem kullanarak, Platformun işleyişini manipüle edecek davranışlarda bulunması,</p>
            <br />
            <p>b) Platform dahilinde bulunan resimleri, metinleri, görsel ve işitsel imgeleri, dosyaları, veri tabanlarını, katalogları ve listeleri izinsiz olarak çoğaltması, kopyalaması, dağıtması ve işlemesi;</p>
            <br />
            <p>c) Kullanıcının diğer Kullanıcı ve/veya kullanıcıların ve/veya üçüncü kişilerin ve/veya hak sahiplerinin haklarına tecavüz eden ve/veya etme tehlikesi bulunan fiillerde bulunması.</p>
            <br />
            <br />
            <p>7.5. Sözleşmenin Süresi</p>
            <br />
            <p>İşbu Sözleşme süresiz olarak düzenlenmiştir. Taraflardan her biri istediği zaman sözleşmeyi fesih hakkına sahiptir.</p>
            <br />
            <br />
            <h4>8. Sorumluluk Kısıtlaması</h4>
            <br />
            <p>TaraBul, 5651 Sayılı Yasa gereğince içerik sağlayıcı sıfatına sahip değildir. Platform ’da yer alan hiçbir bilgi ve ürün TaraBul tarafından üretilmemiş ve ürün tanıtım içeriğine hiçbir müdahalede bulunulmamıştır. TaraBul içerisinde yer alan, fiyat, model, renk, stok bilgileri gibi ürüne ait tanıtım içerikleri ve bilgiler ürünün satışa sunulduğu mağaza tarafından umuma sunulmuş bilgiler olup, sunulan bilgiler "arama optimizasyonu" ile TaraBul’ da görüntülenmektedir. Bu nedenle TaraBul aranan ve karşılaştırma yapılan ürünlerin bulunduğu web sitesi ve mağazalarının vermiş olduğu bilgilerin doğruluğunu araştırmaz ve Kullanıcılarına garanti vermez. Ürünlerin bulunduğu ve yönlendirme yapılan mağazaların varlığı ve verdikleri hizmetlerin tüketici ve mevcut kanunlara uygunluğunu araştırmaz, sitede görüntülenen verileri Kullanıcılara reklam ve pazarlama amaçlı sunmaz.</p>
            <br />
            <br />
            <br />
            <p>İletişim için:</p>
            <p>tarabulapp@gmail.com mail adresine ulaşabilirsiniz.</p>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
        </div>
    )
}
